@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: "IBM Plex Sans JP", sans-serif;
  font-weight: 400;
  font-style: normal;
}
